const PUBLIC_LAYOUT = "default";

const PROVINCES = [
  "Hà Nội",
  "Đà Nẵng",
  "Hải Phòng",
  "Hồ Chí Minh",
  "Cần Thơ",
  "Other",
];

export { PUBLIC_LAYOUT, PROVINCES };
