import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { registerGlobalComponents } from "./utils/import";
import { VueReCaptcha } from "vue-recaptcha-v3";
import "@/assets/styles/tailwind.css";
import "@/assets/styles/global.css";

let app;
app = createApp(App);

app.use(VueReCaptcha, { siteKey: "6Lc4xPYpAAAAAMZmYnrj2S5Ua_AOJuO7a7qzLYrr" });

registerGlobalComponents(app);

app.use(router);

app.mount("#app");
